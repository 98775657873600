<template>
  <v-row>
    <!-- header -->
    <mini-header :breadcrumbs="breadcrumbs"  :hiddenTab="true" :dense="true" :scroll="scroll">
      <template v-slot:main>
        <v-btn v-if="!$store.state.auth.account.id" color="primary" :to="{ name: 'AuthChoose'}"><v-icon left>mdi-arrow-left </v-icon>Volver a organizaciones</v-btn>
      </template>
    </mini-header>
    <!-- end header -->
    <v-col class="px-0" :class="{'mt-16' : !$store.state.auth.account.id}">
      <!-- user -->
      <v-card flat>
        <v-toolbar flat height="46" class="transparent">
          <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-bold">Cuenta de usuario</v-toolbar-title>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-5 py-4">
          <v-list class="v-list-form-medium pa-0 transparent">
            <v-list-item class="pa-0">
              <v-list-item-title>Nombre</v-list-item-title>
              <v-list-item-subtitle class="py-1">
                <v-col cols="6">
                  <v-text-field v-model.trim="$v.user.first_name.$model" :error="$v.user.first_name.$error" outlined hide-details required single-line dense placeholder="Escribe un nombre de usuario" maxlength="30" data-action="auto-focus" />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="pa-0">
              <v-list-item-title>Apellido</v-list-item-title>
              <v-list-item-subtitle class="py-1">
                <v-col cols="6">
                  <v-text-field v-model.trim="$v.user.last_name.$model" :error="$v.user.last_name.$error" outlined hide-details required single-line dense  placeholder="Escribe un nombre" maxlength="30" />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-4 px-5">
          <v-spacer />
          <v-btn @click="resetInput" outlined>Cancelar</v-btn>
          <v-btn @click="patchUser" :loading="patchLoader" color="primary">Guardar cambios</v-btn>
        </v-card-actions>
      </v-card>
      <!-- end user -->

      <!-- credentials -->
      <v-card class="my-8" flat>
        <v-toolbar flat height="46" class="transparent">
          <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-bold">Seguridad de la cuenta</v-toolbar-title>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-5 py-4">
          <v-list class="v-list-form-medium pa-0 transparent">
            <v-list-item class="pa-0">
              <v-list-item-title>Contraseña</v-list-item-title>
              <v-list-item-subtitle class="py-1">
                <v-col cols="4">
                  <v-btn @click.stop="modalPatchPassword=true" color="primary">Cambiar contraseña</v-btn>
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
      <!-- end credentials -->
    </v-col>

    <!-- dialog update password -->
    <v-dialog v-model="modalPatchPassword" width="500" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-toolbar flat height="46" class="transparent">
          <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Cambiar contraseña</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" justify="center" class="mr-3">
              <v-btn @click="modalPatchPassword=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-5 py-4">
          <v-list class="v-list-form-extended-medium pa-0 transparent">
            <v-list-item class="pa-0">
              <v-list-item-title>Contraseña actual</v-list-item-title>
              <v-list-item-subtitle class="py-1">
                <v-text-field class="icon-field" v-model.trim="$v.password.old_password.$model" :error="$v.password.old_password.$error" @click:append="show = !show" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :type="show ? '$v.password.old_password.$model' : 'password'" outlined hide-details required single-line dense maxlength="56" data-action="auto-focus" placeholder="Escribe tu contraseña actual" />
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="pa-0">
              <v-list-item-title>Nueva contraseña</v-list-item-title>
              <v-list-item-subtitle class="py-1">
                <v-text-field class="icon-field" v-model.trim="$v.password.new_password1.$model" :error="$v.password.new_password1.$error" @click:append="show1 = !show1" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'user.password1' : 'password'" outlined hide-details required single-line  dense maxlength="56" data-action="auto-focus" placeholder="Escribe una nueva contraseña segura" />
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="pa-0">
              <v-list-item-title>Confirmar contraseña</v-list-item-title>
              <v-list-item-subtitle class="py-1">
                <v-text-field class="icon-field" v-model.trim="$v.password.new_password2.$model" :error="$v.password.new_password2.$error" @click:append="show2 = !show2" :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'" :type="show2 ? 'user.password2' : 'password'" outlined hide-details required single-line dense maxlength="56" placeholder="Escribe otra vez la nueva contraseña" />
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer />
          <v-btn @click="modalPatchPassword=false" outlined>Cancelar</v-btn>
          <v-btn @click="patchPassword" :loading="patchLoader" color="primary">Cambiar contraseña</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog update password -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import MiniHeader from '@/components/commons/MiniHeader'
import { required, sameAs, minLength, maxLength } from 'vuelidate/lib/validators'
import GenericViewMixin from '@/mixins/GenericViewMixin'
export default {
  components: {
    MiniHeader
  },
  mixins: [
    GenericViewMixin
  ],
  data () {
    return {
      scroll: 0,
      token: [],
      user: {
        first_name: '',
        last_name: ''
      },
      password: {
        new_password1: '',
        new_password2: '',
        old_password: ''
      },
      patchLoader: false,
      patchLoaderToken: false,
      modalPatchPassword: false,
      show: false,
      show1: false,
      show2: false,
      showToken: false,
      breadcrumbs: {
        main: 'Cuenta de usuario',
        children: []
      }
    }
  },
  computed: mapState({
    userDetail: state => state.auth.userAccount
  }),
  created () {
    this.user = {...this.userDetail}
  },
  methods: {
    onScroll (e) {
      this.offsetTop = e.target?.scrollingElement?.scrollTop ?? 0
    },
    resetInput () {
      this.user = {
        first_name: this.userDetail.first_name,
        last_name: this.userDetail.last_name
      }
    },
    patchUser () {
      this.$v.user.$touch()
      if (this.$v.user.$invalid) {
        return false
      }
      this.patchLoader = true
      this.$store.dispatch('auth/PATCH_USER', {
        first_name: this.user.first_name,
        last_name: this.user.last_name
      })
      .then((response) => {
        this.$dialog.message.info('La información de usuario se ha actualizado')
        this.$store.commit('auth/SET_USER', response.data)
      })
      .finally(() => {
        this.patchLoader = false
      })
    },
    patchPassword () {
      this.$v.password.$touch()
      if (this.$v.password.$invalid) {
        return false
      }
      this.patchLoader = true
      this.$store.dispatch('auth/POST_USER_PASSWORD', {
        new_password1: this.password.new_password1,
        new_password2: this.password.new_password2,
        old_password: this.password.old_password
      })
      .then((response) => {
        this.modalPatchPassword = false
        this.patchLoader = false
        this.password = {
          new_password1: '',
          new_password2: '',
          old_password: ''
        }
        this.$v.password.$reset()
        this.$dialog.message.info(response.data.detail)
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.patchLoader = false
      })
    }
  },
  validations: {
    user: {
      first_name: {
        maxLength: maxLength(30)
      },
      last_name: {
        maxLength: maxLength(30)
      }
    },
    password: {
      new_password1: {
        required,
        minLength: minLength(8)
      },
      new_password2: {
        sameAsPassword: sameAs('new_password1')
      },
      old_password: {
        required
      }
    }
  }
}
</script>